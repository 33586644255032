import React, { useState, useEffect, useRef } from "react";
import { motion, useInView } from "framer-motion";
import { useNavigate } from "react-router-dom";
import cv from "../Elouafi_mohammed.pdf";
import Navbar from "./Navbar";

function Home() {
  const navigate = useNavigate();
  const [showFirstSection, setShowFirstSection] = useState(true);
  const [isAimationStart, SetIsAimationStart] = useState(false);

  const handleDownload = () => {
    window.open(cv, "_blank");
  };

  const handleDiscoverMore = () => {
    const navbar = document.getElementById("navbar");
    document.body.classList.remove("hide-scroll");
    setTimeout(() => {
      setShowFirstSection(false);
      SetIsAimationStart(true);
    }, 1000);
    navbar.classList.add("show-navbar");
  };

  useEffect(() => {
    navigate("/#hello");
    setShowFirstSection(true);
    SetIsAimationStart(false);
    setTimeout(() => {
      window.scrollTo(0, 0);
    }, 100);
    document.body.classList.add("hide-scroll");
  }, []);

  return (
    <>
      <Navbar isAimationStart={isAimationStart} />

      {showFirstSection && (
        <div
          id="hello"
          className="hello-page container d-flex justify-content-center align-items-center"
        >
          <motion.div
            className="d-flex text-center flex-column justify-content-center align-items-center"
            initial={{ opacity: 0, y: -20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 1 }}
          >
            <motion.i
              className="fa-solid fa-code"
              initial={{ y: -110 }}
              animate={{ y: 0 }}
              transition={{ duration: 1 }}
            ></motion.i>

            <h1> Hello Friend !</h1>

            <motion.a
              href="/#home"
              whileTap={{ scale: 0.85 }}
              className="px-3 py-2 mt-3"
              onClick={handleDiscoverMore}
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              transition={{ delay: 1, duration: 0.5 }}
            >
              Discover more !
            </motion.a>
          </motion.div>
        </div>
      )}
      <div
        id="home"
        className="home text-effect-wrapper container px-3 px-lg-0 d-flex flex-column align-items-center text-center justify-content-center"
      >
        <h2>Hi, my name is</h2>
        <h1>
          Mohamed Elouafi <br />
          Data + Code = <br />
          <span className={isAimationStart ? " glow" : ""}>Solutions</span>
        </h1>
        <p>
          I am a freelance Data science and ML Engineer, part of the WebGenius
          group offering AI-based web services.
        </p>
        <motion.button
          whileTap={{ scale: 0.85 }}
          className="px-3 py-2"
          onClick={handleDownload}
        >
          Download resume!
        </motion.button>
      </div>
    </>
  );
}

export default Home;
