import React, { useEffect } from 'react'
import { motion } from "framer-motion";
import { HashLink } from 'react-router-hash-link';
function Navbar({isAimationStart}) {
  useEffect(() => {
    const handleScroll = () => {
      const navbar = document.getElementById("navbar");
      if (window.scrollY > 0) {
        navbar.classList.add("navbar-scrolled");
      } else {
        navbar.classList.remove("navbar-scrolled");
      }
    };
    document.addEventListener("scroll", handleScroll);
  
    return () => {
      document.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const navbarAnimations = {
    hidden: {
      opacity: 0, y: -50,
    },
    visible: {
      opacity: 1, y: 0 
    },
    transition: {
      delay: .2}
  };

  return (
    <motion.nav
    initial="hidden"
    animate={isAimationStart ? "visible" : "hidden"}
    variants={navbarAnimations}
     id='navbar' className="navbar  navbar-expand-lg fixed-top">
    <div className="container-fluid d-flex justify-content-center">
    <i
          id="menuIcon"
          className="fa-solid fa-bars d-lg-none my-3 my-lg-0"
          type="button"
          data-bs-toggle="offcanvas"
          data-bs-target="#offcanvasNavbar"
          aria-controls="offcanvasNavbar"
          aria-label="Toggle navigation"
        ></i>
      <div className="offcanvas offcanvas-start text-center" id="offcanvasNavbar" aria-labelledby="offcanvasNavbarLabel">
        <div className="offcanvas-body  d-flex align-items-center justify-content-center  ">
       
          <ul className="navbar-nav justify-content-center  pe-3 ">
            <li className="nav-item mx-3 my-2">
            <i
              type="button"
              className="fa-solid fa-xmark d-block d-lg-none"
              data-bs-dismiss="offcanvas"
              aria-label="Close"
            ></i>
            </li>
            <li className="nav-item mx-3 my-2">
             <HashLink smooth to="/#about" className="nav-link"><span>01.</span>About</HashLink>
            </li>
            <li className="nav-item mx-3 my-2">
             <HashLink smooth to="/#project" className="nav-link"><span>02.</span>Projects</HashLink>
            </li>
            <li className="nav-item mx-3 my-2">
              <HashLink smooth to="/#contact" className="nav-link"><span>03.</span>Contacts</HashLink>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </motion.nav>
  )
}

export default Navbar