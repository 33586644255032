import React, { useRef } from "react";
import { delay, motion, useInView } from "framer-motion";
import { Link } from "react-router-dom";

function ProjectBox({ project }) {
  const ref = useRef(null);
  const inView = useInView(ref, { amount: 0.4 });

  const projectBoxVariants = {
    hidden: { opacity: 0, y: 50 },
    visible: { opacity: 1, y: 0 },
    transition: {
      duration: 5,
      type: "spring",
    },
  };

  return (
    <motion.div
      initial="hidden"
      animate={inView ? "visible" : "hidden"}
      variants={projectBoxVariants}
      className="box-container my-3 my-lg-3 col-md-6 col-lg-4 d-flex justify-content-center"
    >
      <div ref={ref} className="project-box p-4 ">
        <div className="mb-5 d-flex flex-row justify-content-between align-items-center">
          <i class="fa-regular fa-folder folder"></i>
          <div className="d-flex flex-row">
            {project.links.website !== "" && (
              <Link className="links" to={project.links.website}>
                <i class="fa-solid fa-arrow-up-right-from-square "></i>
              </Link>
            )}

            <Link className="links" to={project.links.github}>
              <i class="fa-brands fa-github ms-2"></i>
            </Link>
          </div>
        </div>
        <div className="d-flex flex-column text">
          <h1>{project.title}
          {
            project.state == 0 &&  <span className="ms-2">work in progress</span>
          } </h1> 
          <p>{project.description}</p>
        </div>
        <p className="tech">
          <ul className="d-flex justify-content-between">
            {project.tech.map((tech, index) => (
              <li key={index}>{tech}</li>
            ))}
          </ul>
        </p>
      </div>
    </motion.div>
  );
}

export default ProjectBox;
