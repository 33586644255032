import React, { useRef } from "react";
import { motion, useInView } from "framer-motion";
import { Link } from 'react-router-dom';
import links from "../project.json";
function Contact() {
  const ref = useRef(null);
  const isInView = useInView(ref, { amount: 0.9 });
  const contactVariants = {
    hidden: { opacity: 0, y: 50 },
    visible: { opacity: 1, y: 0 },
    transition: {
      duration: 10,
    },
  };

  const handleSayHelloClick = () => {
    window.location.href = "mailto:elouafimed2@gmail.com?subject=Hello";
  };

  return (
    <div ref={ref} id='contact' className="contact container px-3 px-lg-0 py-5 d-flex flex-column justify-content-center align-items-center">
      <h2>03. What’s Next?</h2>
      <motion.div
        animate={isInView ? "visible" : "hidden"}
        variants={contactVariants}
        className="d-flex flex-column text-center justify-content-center align-items-center">
        <h1>Get in touch</h1>
        <p className='mb-3'>Although I’m not currently looking for any new opportunities, my inbox is always open. Whether you have a question or just want to say hi, I’ll try my best to get back to you!</p>
        <motion.button onClick={handleSayHelloClick} whileTap={{ scale: 0.85 }} className="px-3 py-2 mb-5">Say Hello</motion.button>
      </motion.div>
      <div className="d-block d-lg-none mt-5">
        <Link to={links.PlatformLinks.github}>
          <i class="mx-2 fa-brands fa-github"></i>
        </Link>
        <Link to={links.PlatformLinks.linkedin}>
          <i class="mx-2 fa-brands fa-linkedin-in"></i>
        </Link>
        <Link to={links.PlatformLinks.instagram}>
          <i class="mx-2 fa-brands fa-instagram"></i>
        </Link>
      </div>
    </div>
  );
}

export default Contact;
