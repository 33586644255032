import React, { useRef } from "react";
import { motion, useInView } from "framer-motion";
import image from "../Photo3.png";
function About() {
  const ref = useRef(null);
  const imageRef = useRef(null);
  const isInView = useInView(ref, { amount: 0.3 });
  const isImageInView = useInView(imageRef, { amount: 0.5 });
  const aboutVariants = {
    hidden: { opacity: 0, y: 50 },
    visible: { opacity: 1, y: 0 },
  };
  const imageVariants = {
    hidden: { transform: "scale(0.5)" },
    visible: { transform: "scale(1)" },
  };
  return (
    <div

      id="about"
      className="about container"
    >
      <h1 className="mb-5">
        <span>01.</span>About me
      </h1>
      <div className="row  px-4 px-lg-0 mb-5 pb-5" ref={ref}>
        <motion.div
          animate={isInView ? "visible" : "hidden"}
          variants={aboutVariants}
          transition={{ type: "spring" }}
          className="col-md-8"
        >
          <p>
            I'm Mohamed Elouafi, a passionate 21-year-old{" "}
            I have a bachelor's degree in mathematics and computer science. Currently, I am pursuing a state engineering training.
            I have in-depth skills in <strong>mathematics (linear algebra, arithmetic, analysis, combinatorics, topology, differential calculus, optimization, ...), theoretical computer science, and statistics. Proficient in Python, R, C, Java, C#, JS, PHP, SQL, ...</strong>
            As a self-taught Data Scientist, I began my learning journey in Machine Learning and Deep Learning with Python and R since late 2022. My keen interest also extends to robotics and cybersecurity.
          </p>

        </motion.div>
        <div ref={imageRef} className="mt-5 mt-lg-0 col-md-4 d-flex justify-content-center align-items-center">
          <motion.span animate={isImageInView ? "visible" : "hidden"}
            variants={imageVariants}
            transition={{ duration: .5 }}
          >
            <img src={image} />
          </motion.span>
        </div>
      </div>
    </div>
  );
}

export default About;
