import React from 'react'
import { Link } from "react-router-dom";
import { motion } from "framer-motion";
import links from "../project.json";
function Media() {
  const handleSayHelloClick = () => {
    window.location.href = "mailto:elouafimed2@gmail.com?subject=Hello";
  };
  return (
    <div id='media' className="d-none d-lg-block">
      <motion.div
        initial={{ opacity: 0, x: 50 }}
        animate={{ opacity: 1, x: 0 }}
        transition={{ delay: 1.5, duration: 0.5 }}
        className="email "
      >
        <Link onClick={handleSayHelloClick} >elouafimed2@gmail.com</Link>
      </motion.div>
      <motion.div
        initial={{ opacity: 0, x: -50 }}
        animate={{ opacity: 1, x: 0 }}
        transition={{ delay: 1.5, duration: 0.5 }}
        className="media "
      >
        <Link to={links.PlatformLinks.github}>
          <i class="my-2 fa-brands fa-github"></i>
        </Link>
        <Link to={links.PlatformLinks.linkedin}>
          <i class="my-2 fa-brands fa-linkedin-in"></i>
        </Link>
        <Link to={links.PlatformLinks.instagram}>
          <i class="my-2 fa-brands fa-instagram"></i>
        </Link>
      </motion.div>
    </div>
  )
}

export default Media