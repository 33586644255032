import React from "react";
import data from "../project.json";
import ProjectBox from "./ProjectBox";
function Projects() {
  return (
    <div id="project" className="project container px-3 px-lg-0 mb-5 pb-5">
      <h1 className="mb-5">
        <span>02.</span>Some Things I’ve Built
      </h1>
      <div className="row">
          {data.projects.map((project, index) => (
           <ProjectBox key={index} project={project} />
          ))}
      </div>
    </div>
  );
}

export default Projects;
