import "./App.css";

import { BrowserRouter } from "react-router-dom";
import About from "./components/About";
import Home from "./components/Home";
import Navbar from "./components/Navbar";
import Projects from "./components/Projects";
import Contact from "./components/Contact";
import AOS from "aos";
import "aos/dist/aos.css";
import Media from "./components/Media";
import { useEffect } from "react";
AOS.init({ duration: 1000 });
function App() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <BrowserRouter>
      <Home />
      <About />
      <Projects />
      <Contact />
      <Media />
    </BrowserRouter>
  );
}

export default App;
